<template>
  <div class="border-t pt-4">
    <h2 class="text-xl font-bold text-gray-800 mb-4">Variations</h2>

    <div class="flex justify-between">
      <div class="flex gap-2 items-center">
        <input type="checkbox" />
        <span
          >{{ variations.length }} variation{{
            variations.length > 1 ? "s" : ""
          }}</span
        >
      </div>
      <button
        v-if="!product.id"
        class="text-xs font-semibold text-blue-800 flex items-center gap-x-1 focus:outline-none add-option-btn"
        type="button"
        @click="$emit('toggle-add-options')"
      >
        <img
          src="@/assets/svg/tiny-plus-bold.svg"
          class="w-5 h-5"
          alt="tiny-plus-icon"
        />
        <span class="font-extrabold"> Add Option </span>
      </button>
    </div>

    <div class="mb-8 border rounded-lg overflow-x-scroll mt-4">
      <table class="w-full table-auto">
        <thead class="bg-blue-100">
          <tr>
            <th
              class="text-left whitespace-no-wrap p-4 text-xs font-semibold"
            ></th>
            <th class="text-left whitespace-no-wrap p-4 text-xs font-semibold">
              <span>VARIATION</span>
            </th>
            <th class="text-left whitespace-no-wrap p-4 text-xs font-semibold">
              <span>SELLING PRICE</span>
            </th>
            <th class="text-left whitespace-no-wrap p-4 text-xs font-semibold">
              <span>COST PRICE</span>
            </th>
            <th class="text-left whitespace-no-wrap p-4 text-xs font-semibold">
              <span>SKU</span>
            </th>
            <th class="text-left whitespace-no-wrap p-4 text-xs font-semibold">
              <span>STOCK</span>
            </th>
            <th class="text-left whitespace-no-wrap p-4 text-xs font-semibold">
              <span>MANAGE STOCK</span>
            </th>
          </tr>
        </thead>

        <tbody v-if="variations.length">
          <tr
            v-for="(variation, index) in variations"
            class="cursor-pointer hover:bg-gray-200"
            :class="index % 2 === 0 ? '' : 'bg-gray-100'"
            :key="index"
            @click="handleManageStock(variation, index)"
          >
            <td class="text-xs whitespace-no-wrap p-4">
              <input type="checkbox" />
            </td>
            <td class="text-xs whitespace-no-wrap p-4">
              <span>{{ variation.title }}</span>
            </td>
            <td class="text-xs whitespace-no-wrap p-4">
              <div class="flex gap-1 items-center" @click.stop>
                <span>₦</span>
                <input
                  v-model.number="variations[index].sale_price"
                  type="number"
                  class="py-1 px-0 rounded outline-none bg-transparent"
                  placeholder="0.00"
                />
              </div>
            </td>
            <td class="text-xs whitespace-no-wrap p-4" @click.stop>
              <div class="flex gap-1 items-center">
                <span>₦</span>
                <input
                  v-model.number="variations[index].cost_price"
                  type="number"
                  class="py-1 px-0 rounded outline-none bg-transparent"
                  placeholder="0.00"
                />
              </div>
            </td>
            <td class="text-xs whitespace-no-wrap p-4">
              {{ variations[index].barcode || "N/A" }}
            </td>
            <td class="text-xs whitespace-no-wrap p-4">
              {{ variations[index].stock_quantity || "N/A" }}
            </td>
            <td class="text-xs whitespace-no-wrap p-4">
              <span>
                Stock
                {{
                  variations[index].manage_stock ? "Manageable" : "Not Managed"
                }}
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <EditVariation
      v-if="showEditVariation"
      :selected="selectedVariation"
      @save="handleSave"
      @close="toggleShowEditVariation"
    />
  </div>
</template>

<script>
import EditVariation from "./EditVariation";

export default {
  name: "ProductVariation",
  components: {
    EditVariation,
  },
  emits: ["toggle-add-options", "update-variations"],
  props: {
    product: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      variations: [],
      selectedVariation: {},
      showEditVariation: false,
    };
  },
  created() {
    if (this.product?.variations?.length) {
      this.variations = [...(this.product?.variations ?? [])];
    }
  },
  computed: {
    variationsFormatter() {
      const resultFormat = (data) => {
        const formatted = data[0].values.map((title) => {
          const option = this.product.options?.find(
            (opt) => opt.id === data[0].id
          );

          return {
            title,
            options: [
              {
                id: option.id,
                key: option.key,
                value: title,
              },
            ],
          };
        });

        // console.log(addedVariationFields, addedVariationFieldIndex, "added>>>");

        return this.includeVariationData(formatted);
      };

      if (this.product.options?.length === 1)
        return resultFormat(this.product.options ?? []);

      // Get the newly added option first to replace the previously added ones
      const allUniqueValues = [...(this.product.options ?? [])]
        .reverse()
        .reduce((acc, { key, values, id }) => {
          if (acc.find((a) => a.key === key)) {
            return [...acc];
          }

          return [
            ...acc,
            {
              key,
              values,
              id,
            },
          ];
        }, []);

      if (allUniqueValues?.length === 1) return resultFormat(allUniqueValues);

      const variations = [...allUniqueValues]
        .slice(1)
        .reduce((acc1, _, index) => {
          const arr = [...allUniqueValues][index].values.reduce(
            (acc, value) => {
              const restValues = allUniqueValues
                .slice(index + 1)
                .reduce((acc2, curr) => {
                  const arrVals = curr.values.map((value2) => ({
                    key: curr.key,
                    restVal: value2,
                    id: curr.id,
                  }));

                  return [...acc2, ...arrVals];
                }, []);

              const data = restValues.map(({ restVal, id }) => {
                const values = [value, restVal];
                return {
                  title: `${values[0]}, ${values[1]}`,
                  options: [[...allUniqueValues][index].id, id].map(
                    (idx, index) => {
                      const option = this.product.options?.find(
                        (opt) => opt.id === idx
                      );
                      return {
                        id: option.id,
                        key: option.key,
                        value: values[index],
                      };
                    }
                  ),
                };
              });

              return [...acc, ...data];
            },
            []
          );

          return [...acc1, ...arr];
        }, []);

      return this.includeVariationData(variations);
    },
  },
  watch: {
    "product.options": {
      handler() {
        this.variations = [...this.variationsFormatter];
      },
      immediate: true,
    },
    variations: {
      handler(val) {
        this.$emit("update-variations", val);
      },
      deep: true,
    },
  },
  methods: {
    handleManageStock(variation, index) {
      // this.variations[index].manage_stock = !variation?.manage_stock;
      // if (this.variations?.[index]?.manage_stock) {
      //   this.selectedVariation = {
      //     variation,
      //     index,
      //   };
      this.selectedVariation = {
        variation,
        index,
      };
      this.toggleShowEditVariation();
      // }
    },
    toggleShowEditVariation() {
      this.showEditVariation = !this.showEditVariation;
    },
    includeVariationData(arr) {
      // Populate with variation data if variation exists {
      const formatted = arr.map((f) => {
        const addedVariationFields =
          this.product.variations?.find((variation) =>
            variation.options?.some((option) =>
              f?.options?.some(
                (opt) => opt.id === option.id && opt.value === option.value
              )
            )
          ) ?? {};

        return {
          ...f,
          ...addedVariationFields,
        };
      });

      return formatted;
    },
    handleSave(data) {
      const newVariations = [...this.variations];
      newVariations[data.index] = { ...data.variation };
      this.variations = newVariations;

      if (data?.close) {
        this.toggleShowEditVariation();
        return;
      }

      this.selectedVariation = {
        variation: data.variation,
        index: data.index,
      };
    },
  },
};
</script>

<style lang="scss" scoped></style>
