<template>
  <trac-dashboard-table :data="options" :tableHeaders="headers">
    <template #default="{ row, index }">
      <trac-dashboard-table-data>{{ row.key }}</trac-dashboard-table-data>
      <trac-dashboard-table-data>
        <div class="flex gap-5 flex-wrap">
          <trac-capsule
            v-for="value in row.values"
            :key="value"
            class="cursor-pointer"
            :name="value"
            @close="handleOptionSelect({ value, index })"
          />
        </div>
      </trac-dashboard-table-data>
      <trac-dashboard-table-data>
        <button
          type="button"
          class="focus:outline-none"
          @click="deleteOption(index)"
        >
          <div class="hover:bg-lightGray p-2 rounded-md">
            <img
              src="@/assets/svg/trash-2.svg"
              class="mx-auto w-full"
              alt="trash-icon"
            />
          </div>
        </button>
      </trac-dashboard-table-data>
    </template>
  </trac-dashboard-table>
</template>

<script>
export default {
  name: "OptionsTable",
  emits: ["set-options"],
  props: {
    options: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      headers: [
        {
          name: "OPTION NAME",
        },
        {
          name: "OPTION VALUES",
          classValue: "w-3/6",
        },
        {
          name: "",
        },
      ],
    };
  },
  methods: {
    handleOptionSelect({ value, index }) {
      const updatedOptions = [...this.options];
      updatedOptions[index].values = updatedOptions[index].values.filter(
        (option) => option !== value
      );
      this.$emit(
        "set-options",
        [...updatedOptions].filter((options) => !!options?.values?.length)
      );
    },
    deleteOption(index) {
      const updatedOptions = [...this.options].filter(
        (_, idx) => index !== idx
      );
      this.$emit("set-options", updatedOptions);
    },
  },
};
</script>

<style lang="scss" scoped></style>
