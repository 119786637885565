<template>
  <div>
    <trac-loading v-if="loading" />
    <trac-button
      v-if="showTriggerButton"
      class="uppercase"
      id="create-option"
      @button-clicked="toggleModal"
    >
      Create Options
    </trac-button>

    <trac-modal v-if="showModal" @close="toggleModal">
      <div class="pb-3 border-b border-[#D8D8D8]">
        <h4 class="font-bold text-2xl">{{ typeText }} Options</h4>
      </div>

      <form @submit.prevent class="max-w-xl ma w-full mt-12">
        <div class="flex flex-col gap-4">
          <div>
            <trac-input
              v-model.trim="formData.key"
              placeholder="Option name"
              class="mt-5"
              ref="name"
              type="text"
              tag="div"
            />
          </div>
          <div class="relative">
            <trac-input
              v-model.trim="value"
              placeholder="Enter value"
              class="mt-5"
              ref="value"
              type="text"
              tag="div"
              @keyup-enter="keyUp"
            />
            <button
              v-if="value"
              class="flex items-center justify-center text-xs absolute bg-primaryBlue text-white h-10 font-medium self-end rounded-r-sm mt-2 cursor-pointer px-3"
              id="add-option"
              type="button"
              style="bottom: 1px; right: 1px"
              @click="addValue"
            >
              Add value
            </button>
          </div>
        </div>

        <div class="flex gap-2 flex-wrap mt-3">
          <trac-capsule
            v-for="(value, index) in formData.values"
            :key="value"
            :name="value"
            @close="removeValue(index)"
          />
        </div>

        <div class="mt-3 grid grid-cols-12 gap-2">
          <trac-checkbox
            class="col-span-6"
            data-test="modifier_option"
            value="modifier"
            :is-checked="formData.type.includes('modifier')"
            @check="setCheck"
          >
            <p class="ml-3 text-xs">Use as Modifier option</p>
          </trac-checkbox>
          <trac-checkbox
            class="col-span-6"
            data-test="product_option"
            value="simple"
            :is-checked="formData.type.includes('simple')"
            @check="setCheck"
          >
            <p class="ml-3 text-xs">Use as Product option</p>
          </trac-checkbox>
        </div>

        <div class="flex flex-col items-center gap-8 mb-6 mt-32">
          <trac-button
            class="w-2/5"
            type="button"
            id="clickToAdd"
            :disabled="!formData.key || !formData.values.length || loading"
            @button-clicked="submitOption"
          >
            <span class="capitalize">{{ typeText }}</span> options
          </trac-button>
        </div>
      </form>
    </trac-modal>

    <trac-modal v-if="showSuccessModal" @close="toggleSuccess">
      <div class="flex flex-col items-center gap-8 mb-6">
        <img src="@/assets/svg/success-2.svg" alt="success-icon" />
        <p>{{ successText }}</p>
        <trac-button
          class="w-3/5"
          id="clickToAdd"
          @button-clicked="toggleSuccess"
        >
          CLOSE
        </trac-button>
      </div>
    </trac-modal>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { eventBus } from "@/eventBus";

export default {
  emits: ["close", "click"],
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    showTriggerButton: {
      type: Boolean,
      default: true,
    },
    selectedOption: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      showModal: false,
      showSuccessModal: false,
      successText: "",
      value: "",
      formData: {
        values: [],
        type: [],
      },
      loading: false,
    };
  },
  computed: {
    typeText() {
      return !this.selectedOption?.key ? "Create" : "Edit";
    },
  },
  methods: {
    ...mapActions(["CREATE_OPTIONS", "EDIT_OPTIONS"]),
    toggleModal() {
      this.showModal = !this.showModal;
      this.$emit("click");
    },
    addValue() {
      this.formData.values.push(this.value);
      this.value = "";
    },
    removeValue(index) {
      this.formData.values.splice(index, 1);
    },
    async submitOption() {
      console.log("here");
      const dispatch =
        this.typeText === "Edit" ? "EDIT_OPTIONS" : "CREATE_OPTIONS";
      this.loading = true;

      try {
        const res = await this[dispatch](
          this.typeText !== "Edit"
            ? this.formData
            : {
                ...this.formData,
                key:
                  this.formData.key === this.selectedOption?.key
                    ? undefined
                    : this.formData.key,
              }
        );

        if (res?.status) {
          this.successText = `Options has been ${
            this.typeText !== "Edit" ? "created" : "updated"
          } successfully.`;
          this.showSuccessModal = true;
          this.toggleModal();
        }
      } catch (error) {
        eventBus.$emit("trac-alert", { message: error.response.data.message });
      } finally {
        this.loading = false;
      }
    },
    toggleSuccess() {
      this.showSuccessModal = !this.showSuccessModal;
      this.$emit("close");
    },
    setCheck({ value, checked }) {
      if (checked) {
        this.formData.type = [...this.formData.type, value];
        return;
      }
      const newType = this.formData.type.filter((t) => t !== value);
      this.formData.type = [...newType];
    },
    keyUp() {
      this.value && this.addValue();
    },
  },
  watch: {
    showModal: {
      handler(val) {
        if (val && !this.selectedOption.key) {
          this.formData = {
            values: [],
            type: [],
          };
          return;
        }

        Object.assign(this.formData, {
          key: this.selectedOption?.key,
          values: this.selectedOption?.values,
          optionId: this.selectedOption?._id,
        });
      },
      immediate: true,
    },
    show(val) {
      this.showModal = val;
    },
  },
};
</script>

<style lang="scss" scoped></style>
