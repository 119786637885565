<template>
  <div>
    <trac-loading v-if="loading" />
    <div class="mt-8">
      <div class="relative">
        <span class="text-xs font-light text-primaryGray w-full">
          <trac-input
            data-test="search-stores"
            v-model="searchTerm"
            type="text"
            name=""
            id=""
            placeholder="Search store..."
            class="w-full py-2 outline-none"
          />
        </span>
        <div class="absolute position-right">
          <img src="@/assets/svg/Search_icon.svg" />
        </div>
      </div>

      <table class="mt-8 mb-16 w-full border-collapse">
        <thead>
          <tr>
            <th class="w-5/6"></th>
            <th align="right" colspan="1" class="text-xs font-bold text-center">
              QTY
            </th>
            <th align="right" colspan="2" class="text-xs font-bold text-center">
              Reorder
            </th>
          </tr>
        </thead>
        <tbody class="w-full">
          <tr
            v-for="(store, index) in filteredStores"
            :key="store.name || store.store_name"
            class="py-2"
          >
            <td class="py-2 w-5/6">
              <span class="text-accentDark capitalize">
                {{ store.name || store.store_name }}
              </span>
            </td>
            <td align="right" class="text-center">
              <input
                v-model.number="
                  stores.find(
                    (store) =>
                      index ===
                      findStoreIndex(
                        store._id || store.store_i_d || store.store_id
                      )
                  ).stock_quantity
                "
                type="number"
                class="border border-primaryGray rounded-md w-20 p-1 mx-2 text-center focus:outline-none text-sm"
                :disabled="variation.id"
              />
            </td>
            <td align="right" class="text-center">
              <input
                v-model.number="
                  stores.find(
                    (store) =>
                      index ===
                      findStoreIndex(
                        store._id || store.store_i_d || store.store_id
                      )
                  ).re_order
                "
                type="number"
                class="border border-primaryGray rounded-md w-20 p-1 mx-2 text-center focus:outline-none text-sm"
                :disabled="variation.id"
              />
            </td>
          </tr>
        </tbody>
      </table>

      <div class="flex flex-col items-center">
        <template>
          <trac-button @button-clicked="submit"> UPDATE STOCK </trac-button>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { eventBus } from "../../../eventBus";
import { GET_LOCAL_DB_DATA } from "../../../browser-db-config/localStorage";

export default {
  name: "ManageStock",
  emits: ["submit"],
  props: {
    props: {
      type: Object,
    },
  },
  data() {
    return {
      variation: {},
      searchTerm: "",
      stores: [],
      loading: false,
    };
  },
  created() {
    this.variation = { ...this.props.variation };
    this.stores = [...(this.props.variation?.store_stock ?? [])];
    this.getFullStoreData();
  },
  computed: {
    filteredStores() {
      return this.stores.filter(
        (store) =>
          store?.name?.toLowerCase().match(this.searchTerm?.toLowerCase()) ||
          store?.store_name?.toLowerCase().match(this.searchTerm?.toLowerCase())
      );
    },
  },
  methods: {
    ...mapActions(["HARD_FETCH_ALL_STORES"]),
    getAssignedStoresFromSession() {
      const sessionData = GET_LOCAL_DB_DATA("traction-app-user-data");
      const stores =
        (sessionData || { user: { stores: [] } }).user.stores || [];
      // this.stores = stores
      return stores;
    },
    findStoreIndex(_id) {
      return this.filteredStores.findIndex(
        (st) => (st._id || st.store_i_d || st.store_id) === _id
      );
    },
    async getFullStoreData() {
      this.loading = true;
      try {
        const fetchStores = await this.HARD_FETCH_ALL_STORES();
        if (fetchStores.status) {
          const storeList = fetchStores.data || [];
          // get all stores from session
          const assignedStores = this.getAssignedStoresFromSession();
          // debugger
          // for each store in session, find the store detail from all stores and format for use as list
          const stores = assignedStores.map((store) => {
            const assignedStore = storeList.find(
              (item) => item._id === store.id
            );
            return assignedStore;
            // debugger
          });

          this.stores = this.props.variation?.store_stock ?? stores;
        }
      } catch (error) {
        return eventBus.$emit("trac-alert", {
          message: res?.response?.data?.message,
        });
      } finally {
        this.loading = false;
      }
    },
    submit() {
      const totalStock = this.stores.reduce(
        (acc, store) => acc + (store.stock_quantity ?? 0),
        0
      );

      if (!totalStock) {
        eventBus.$emit("trac-alert", {
          message: `No stock amount added from the stores.`,
        });
        return;
      }

      this.variation.stock_quantity = totalStock;

      // if (totalStock !== this.variation.stock_quantity) {
      //   return eventBus.$emit("trac-alert", {
      //     message: `Total stock added must be equal to the available stock of ${this.variation.stock_quantity}`,
      //   });
      // }

      if (!this.stores?.some((st) => !!st.stock_quantity)) {
        eventBus.$emit("trac-alert", {
          message: `Add a stock quantity to at least one store`,
        });
      }

      const formattedStores = this.stores.map(
        ({
          name,
          store_name,
          _id,
          store_i_d,
          store_id,
          stock_quantity,
          re_order,
        }) => ({
          store_name: name || store_name,
          store_id: _id || store_i_d || store_id,
          stock_quantity: stock_quantity || 0,
          re_order: re_order || 0,
        })
      );

      this.$emit("submit", {
        variation: {
          ...this.variation,
          store_stock: formattedStores,
        },
        index: this.props.index,
      });

      eventBus.$emit("trac-alert", {
        message: "Stock updated successfully",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.position-right {
  top: 50%;
  transform: translateY(-50%);
  right: 30px;
}
</style>
