<template>
  <div>
    <trac-loading v-if="isLoading" />
    <div class="mt-8 mb-20 flex flex-col gap-5">
      <trac-input :value="variation.title" disabled placeholder="Variation" />
      <trac-input
        v-model.number="variation.cost_price"
        type="number"
        placeholder="Cost Price"
      />
      <trac-input
        v-model.number="variation.sale_price"
        type="number"
        placeholder="Selling Price"
      />
      <div class="relative">
        <trac-input
          v-model.number="variation.barcode"
          type="number"
          placeholder="SKU / Barcode"
        />
        <div class="absolute generate-btn">
          <button
            type="button"
            class="px-2 py-1 text-primaryBlue rounded-lg border border-primaryBlue text-xs focus:outline-none"
            @click="generateBarcode"
          >
            GENERATE
          </button>
        </div>
      </div>
      <!-- <trac-input
        v-model.number="variation.re_order"
        type="number"
        placeholder="Reorder level"
      /> -->
      <div class="flex gap-2 items-center justify-between">
        <span class="text-sm">Manage Stock</span>
        <trac-toggle
          :active="variation.manage_stock"
          @newval="
            variation = {
              ...variation,
              manage_stock: !$event,
            }
          "
        />
      </div>
    </div>

    <div class="flex justify-center">
      <trac-button :disabled="isLoading" @button-clicked="submit">
        {{ buttonText }}
      </trac-button>
    </div>
  </div>
</template>

<script>
import { eventBus } from "@/eventBus";
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  emits: ["save"],
  name: "EditVariationDetails",
  props: {
    props: {
      type: Object,
    },
  },
  data() {
    return {
      variation: {},
      isLoading: false,
    };
  },
  created() {
    this.variation = { ...this.props.variation };
  },
  computed: {
    ...mapGetters(["GET_GENERATED_BARCODE"]),
    buttonText() {
      return this.variation.manage_stock ? "NEXT" : "DONE";
    },
  },
  methods: {
    ...mapActions(["GENERATE_BARCODE"]),
    ...mapMutations(["POPULATE_GENERATED_BARCODE"]),
    async generateBarcode() {
      this.isLoading = true;
      await this.GENERATE_BARCODE();
      const res = this.GET_GENERATED_BARCODE;

      this.$store.commit("POPULATE_GENERATED_BARCODE", null);

      if (res.status) {
        this.variation.barcode = res.data;
      } else {
        eventBus.$emit("trac-alert", { message: res?.response?.data?.message });
      }
      this.isLoading = false;
    },
    submit() {
      // if (this.variation.stock_quantity < this.variation.re_order) {
      //   eventBus.$emit("trac-alert", {
      //     message: "Stock level must be more than the re-order level",
      //   });
      //   return;
      // }
      if (this.buttonText === "NEXT") {
        this.$emit("save", {
          variation: this.variation,
          index: this.props.index,
          next: "Manage Stock",
        });
        return;
      }

      this.$emit("submit", {
        variation: { ...this.variation, store_stock: null, stock_quantity: 0 },
        index: this.props.index,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.generate-btn {
  top: 50%;
  transform: translateY(-50%);
  right: 15px;
}
</style>
