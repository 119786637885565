<template>
  <div>
    <trac-loading v-if="loading" />

    <trac-modal
      v-if="showAddOptions"
      @close="
        () => {
          showAddOptions = false;
          $emit('close');
        }
      "
    >
      <div class="pb-3 border-b border-[#D8D8D8]">
        <h4 class="font-bold text-2xl">Add Options</h4>
      </div>

      <div v-if="!loading">
        <p v-if="!options.length" class="py-3">
          No options added.
          <span
            class="underline cursor-pointer text-primaryBlue"
            @click="handleCreateOptions"
          >
            Create
          </span>
        </p>
        <div v-else class="mb-10">
          <trac-dropdown
            :choice="true"
            title="Select Option"
            :menuItems="options.map(({ key, _id }) => ({ name: key, id: _id }))"
            v-model="option"
            class="w-full py-3"
          >
          </trac-dropdown>
          <div v-if="valuesFromOption.length" class="flex gap-3 flex-wrap">
            <trac-capsule
              v-for="value in valuesFromOption"
              :key="value"
              hide-cancel-icon
              class="cursor-pointer"
              :class="{
                'border border-primaryBlue': selectedOptions.includes(value),
              }"
              :name="value"
              @click.native="handleOptionSelect(value)"
            />
          </div>
        </div>

        <div class="mt-8 flex items-center justify-center flex-col">
          <trac-button
            :disabled="!selectedOptions.length"
            button-type="button"
            @button-clicked="addOptionsToProduct"
          >
            ADD OPTIONS
          </trac-button>
        </div>
      </div>
    </trac-modal>

    <CreateOptions
      :showTriggerButton="false"
      :show="showCreateOptions"
      @click="showAddOptions = true"
      @close="closeCreateOptions"
    />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { eventBus } from "@/eventBus";
import CreateOptions from "../Options/CreateOptions.vue";
import ProductVariation from "../ProductVariation.vue";

export default {
  name: "AddOptions",
  emits: ["add-option", "close"],
  props: {
    product: {
      type: Object,
      default: () => {},
    },
    toggleAddOptions: {
      type: Boolean,
    },
    optionType: {
      type: String,
      default: "simple",
    },
  },
  components: { CreateOptions, ProductVariation },
  data() {
    return {
      showAddOptions: false,
      loading: false,
      options: [],
      option: "",
      selectedOptions: [],
      showCreateOptions: false,
    };
  },
  created() {
    this.showAddOptions = true;
    this.fetchOptions();
  },
  computed: {
    valuesFromOption() {
      if (this.option?.id) {
        return (
          this.options.find(({ _id }) => _id === this.option?.id)?.values ?? []
        );
      }

      return [];
    },
  },
  methods: {
    ...mapActions(["FETCH_OPTIONS"]),
    async fetchOptions() {
      this.loading = true;
      try {
        const res = await this.FETCH_OPTIONS({
          type: this.optionType,
        });
        this.options = res.data ?? [];
      } catch (error) {
        eventBus.$emit("trac-alert", {
          message: error.response?.data?.message,
        });
      } finally {
        this.loading = false;
      }
    },
    handleOptionSelect(value) {
      if (this.selectedOptions.includes(value)) {
        this.selectedOptions = this.selectedOptions.filter(
          (option) => option !== value
        );
        return;
      }

      this.selectedOptions.push(value);
    },
    addOptionsToProduct() {
      this.$emit("add-option", {
        key: this.option.name,
        values: this.selectedOptions,
        id: this.option.id,
      });
      this.showAddOptions = false;
    },
    handleCreateOptions() {
      this.showCreateOptions = true;
      this.showAddOptions = false;
    },
    closeCreateOptions() {
      this.showCreateOptions = false;
      this.showAddOptions = true;
      this.fetchOptions();
    },
  },
  watch: {
    "option.name"() {
      this.selectedOptions = [];
    },
    toggleAddOptions: {
      handler() {
        this.showAddOptions = true;
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped></style>
