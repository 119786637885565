<template>
  <div class="mt-10">
    <div v-for="(stock, i) in store_stock" :key="i">
      <span class="font-bold text-lg capitalize">{{
        stock.name || stock.store_name
      }}</span>
      <div class="grid md:flex flex-row md:w-11/12 mt-4 md:mt-8 md:mb-12">
        <div class="flex-column md:w-4/12 md:mr-12">
          <trac-input
            :disabled="$route.params.id || $route.query.id"
            v-model.trim.number="stock.stock_quantity"
            placeholder="Stock Level"
          />
        </div>
        <div class="flex-column md:w-4/12 md:mr-12 my-6 md:my-0">
          <trac-input
            :disabled="$route.params.id || $route.query.id"
            v-model.trim.number="stock.re_order"
            placeholder="Reorder Level"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ManageStock",
  emits: ["set-store-stock"],
  props: {
    product: {
      type: Object,
      default: () => {},
    },
    stores: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      store_stock: [],
    };
  },
  created() {
    const findStoreDetails = (key, store) => {
      return this.product.store_stock?.find(
        (st) => (st.store_id || st.store_i_d) === store._id
      )?.[key];
    };
    this.store_stock = [...(this.stores ?? [])]?.map((store) => ({
      store_id: store._id,
      store_name: store.name,
      stock_quantity: findStoreDetails("stock_quantity", store),
      re_order:
        findStoreDetails("re_order", store) ||
        findStoreDetails("re_order_level", store) ||
        0,
    }));
  },
  watch: {
    store_stock: {
      handler(stocks) {
        this.$emit(
          "set-store-stock",
          stocks.filter((st) => !!st.stock_quantity)
        );
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped></style>
