<template>
  <trac-modal v-bind="$attrs" @close="$emit('close')">
    <div class="pb-3">
      <h4 class="font-bold text-2xl">Edit Variation</h4>
      <trac-tab-window
        section-title="empty"
        :selected-tab="selectedTab"
        :subLinks="subLinks"
        component-mode
        @set-selected-tab="selectedTab = $event"
        @save="handleTabSave"
        @submit="handleSubmit"
      />
    </div>
  </trac-modal>
</template>

<script>
import Details from "./Details.vue";
import ManageStock from "./ManageStock.vue";
// import AddOptions from "./AddOptions.vue";

export default {
  emits: ["close"],
  name: "EditVariation",
  props: {
    selected: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      selectedTab: "Details",
      subLinks: [
        {
          name: "Details",
          selected: false,
          routerWindowClassString: "bg-white mt-12 p-12 big-shadow",
          dataAttribute: "details",
          component: Details,
          componentProps: this.selected,
        },
        {
          name: "Manage Stock",
          selected: false,
          routerWindowClassString: "bg-white mt-12 p-12 big-shadow",
          dataAttribute: "manage-stock",
          component: ManageStock,
          componentProps: this.selected,
        },
        // {
        //   name: "Add Options",
        //   selected: false,
        //   routerWindowClassString: "bg-white mt-12 p-12 big-shadow",
        //   dataAttribute: "add-options",
        //   component: AddOptions,
        //   componentProps: this.selected,
        // },
      ],
    };
  },
  methods: {
    handleTabSave(obj) {
      obj.next && (this.selectedTab = obj.next);
      this.$emit("save", obj);
    },
    handleSubmit(obj) {
      this.$emit("save", { ...obj, close: true });
    },
  },
  watch: {
    selected(val) {
      this.subLinks.forEach((link) => (link.componentProps = val));
    },
  },
};
</script>

<style lang="scss" scoped></style>
